


















import { Component, Vue } from "vue-property-decorator";
import Radio from "@/components/Radio.vue";
import Popup from "@/components/Popup.vue";
import {MoveDirection, tsParticles} from "tsparticles";

@Component({
  components: {
    Radio,
    Popup
  },
})
export default class Home extends Vue {
  showPopup = false;
  changeShowPopup() {
    this.showPopup = !this.showPopup;
  }

  PARTICLE_CONFIG = {
    particles: {
    number: {
      value: 50,
      density: {
        enable: true,
        value_area: 800
      }
    },
    // color: {
    //   value: ["#fdcf58", "#757676", "#f27d0c", "#800909", "#f07f13"]
    // },
    opacity: {
      value: 0.4,
      random: true
    },
    size: {
      value: 3,
      random: true
    },
    move: {
      enable: true,
      direction: MoveDirection.right,
      speed: 50,
      random: true
    },
    line_linked: {
      enable: false
    }
  },
    interactivity: {
      events: {
      onclick: {
        enable: true,
        mode: "repulse"
      },
      resize: true
    }
    }
  }
  initializeParticles() {
    const config = this.PARTICLE_CONFIG
    tsParticles.load('particles-slow', config)
  }
  created() {
    // window.addEventListener('load', () => {
    //   this.initializeParticles()
    // })
  }
}
