


































import { Component, Vue } from "vue-property-decorator";
import PlayButton from "./PlayButton.vue";
import Volume from "./Volume.vue";
import MusicalNote from "./MusicalNote.vue";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

@Component({
  components: {
    PlayButton,
    Volume,
    MusicalNote,
    PulseLoader
  },
})
export default class Radio extends Vue {
  player!: HTMLAudioElement;
  isPlaying = false;
  color = "var(--primary)"
  canPause = false;
  loadingPlay = false;
  volume = 0;

  get showLoader() {
    return this.loadingPlay
  }

  get showMusicNote() {
    return this.isPlaying && !this.loadingPlay
  }

  playPause(option: string) {
    if (option == "play") {
      this.loadingPlay = true;
      this.player.load();
      let maxVol = this.player.volume * 100;
      this.player.volume = 0;
      this.player.play().then(() => {
        this.volume = 0
        this.fadeIn(maxVol)
        this.loadingPlay = false;
      });
    } else if (this.canPause) {
      this.player.pause();
    }
  }

  fadeIn(max:number) {
    var vol = this.volume;
    if (vol < max) {
      this.player.volume = vol / 100;
      this.volume = this.volume + 10;
      setTimeout(() => {
        this.fadeIn(max);
      }, 100);
    } else {
      this.player.volume = max / 100;
    }
  }

  created() {
    this.player = new Audio("http://stream.zeno.fm/9h30ctneu68uv");
    this.player.onpause = () => {
      this.isPlaying = false;
      this.canPause = false;
    }
    this.player.onplay = () => {
      this.isPlaying = true;
      this.canPause = true;
    }
  }
}
