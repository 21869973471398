



















import { Component, Vue } from "vue-property-decorator";

@Component
export default class MusicalNote extends Vue {
  color = "var(--primary)";

  AddObjects(element:any, noteSchema:any) {
    for (var i=0; i<=10-1; i++) { //set count in css too for number of elements
      var note = document.createElement("div");
      note.className = "note note" + String(i + 1);
      note.innerHTML = noteSchema;
      element.appendChild(note);
    }
  }
  mounted () {
    this.AddObjects(document.body.querySelector(".flying-notes"), 
      document!.body!.querySelector!(".note-schema")!.innerHTML);
  }
}
