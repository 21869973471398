















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class PlayButton extends Vue {
  @Prop() isPlaying: boolean = false;
  @Prop() playPause!: Function;
  size: number = 60;
}
