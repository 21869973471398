
















import {Component, Prop, Vue, Watch} from "vue-property-decorator"

@Component
export default class Volume extends Vue {
  @Prop() player!: HTMLAudioElement;
  previousVolume = 35;
  vol = 80;

  get muted() {
    return this.vol / 100 === 0;
  }

  @Watch("vol")
  onVolumeChange() {
    this.player.volume = this.vol / 100;
  }

  mute() {
    if (this.muted) {
      return this.vol = this.previousVolume;
    }
    this.previousVolume = this.vol;
    this.vol = 0;
  }

  created() {
    this.player.volume = this.vol / 100;
  }
}
